import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/HomePage";
import About from "./pages/About";
import Order from "./pages/Order";
import ServicesPage from "./pages/Services";
import ContactUs from "./pages/Contact";

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />

      <Route path="/services" element={<ServicesPage />} />

      <Route path="/why" element={<Order />} />

      <Route path="/contact" element={<ContactUs />} />

      <Route path="/about" element={<About />} />

    </Routes>
  );
}
