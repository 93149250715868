import React from 'react';

export default function About() {
    return (
        <article className="about-us">
            <section className="about">
                <h2>Your Gateway to Global Education</h2>
                <p className="about-subtext">
                    Welcome to Rethink Study, your trusted partner in unlocking global educational opportunities. We specialize in guiding and assisting international students on their journey to pursue quality education abroad.

                    At Rethink Study, we understand the unique challenges and aspirations of students seeking international education. Our experienced team of consultants is dedicated to providing personalized support, helping you navigate the complex process of university admissions with ease.

                    We take pride in our comprehensive services, offering assistance with university selection, application processes, visa procedures, and cultural adaptation. Our goal is to simplify the path to your dream education destination, ensuring a smooth transition and a rewarding academic experience.

                    Rethink Study is committed to delivering reliable and up-to-date information about universities, courses, and study destinations worldwide. We believe in the transformative power of education and are passionate about connecting students with institutions that align with their academic and career goals.

                    Join us at Rethink Study, where we redefine the possibilities of international education and empower you to achieve your academic aspirations on a global scale.
                </p>
            </section>

            <section className="double-image">
                <img className="about-1" src={require('../../../assets/homepage.png')} alt="Rethink Study students exploring global education opportunities"></img>
                {/* 
                Uncomment the next line if you have a second image to display 
                <img className="about-2" src={require('../../../assets/international_students/2.png')} alt="Rethink Study consultation with students"></img>
                */}
            </section>
        </article>
    );
}
