import { Link } from "react-router-dom";

export default function Navigation(props) {
  return (
    <menu className={`navbar-menu ${props.device}`}>
      {props.device === "mobile" ? (
        ""
      ) : (
        <Link to="/">
          <img
            src={require("../assets/nav-logo.png")}
            alt="Rethink logo"
            className="nav-image"
          ></img>
        </Link>
      )}
      <Link className="hover-effect" to="/">
        <h1>Home</h1>
      </Link>
      
      <Link className="hover-effect" to="/services">
        <h1>Our Services</h1>
      </Link>
     
      <Link className="hover-effect" to="/why">
        <h1>Why Choose Us</h1>
      </Link>
      <Link className="hover-effect" to="/contact">
        <h1>Contact Us</h1>
      </Link>
      {/* <a
        className="hover-effect"
        href={"Google Forms Link"}
        target="_blank"
        rel="noreferrer"
      >
        <h1>Register</h1>
      </a> */}
    </menu>
  );
}
