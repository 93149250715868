import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-logo">
          <img
            src={require("../assets/footer-logo.png")}
            alt="Footer Logo"
          ></img>
        </div>

        <div className="footer-contact">
          <h1 className="footer-header">Contact</h1>
          <ul className="footer-address">
    <li>Rethink Study</li>
    <li>3rd Floor</li>
    <li>7 Kirkdale Rd</li>
    <li>Bushwood, London E11 1HP, UK</li>
</ul>
<ul className="footer-contact-details">
   
    <li>
        <a
            href="mailto:contact@rethinkstudy.com"
            target="_blank"
            rel="noreferrer"
        >
            contact@rethinkstudy.com
        </a>
    </li>
</ul>

        </div>

        <div className="footer-social">
          <h1 className="footer-header">Connect</h1>
          <ul className="footer-social-links">
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61556227289750"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com//"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noreferrer"
              >
                YouTube
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
