import React from 'react';

import UniversitySelection from '../../../assets/serv1.jpeg';
import VisaAssistance from '../../../assets/serv2.jpeg';
import CareerGuidance from '../../../assets/serv3.jpeg';
import ServiceCard from '../ServiceCard';

export default function Specials() {
    return (
        <section className="specials"> {/* Keeping the class name as "specials" for consistency */}
            <article className="specials-topbar">
                <h1>Our Services</h1>
                <p>Explore the range of services we offer to make your international education journey smooth and successful.</p>
                <a className="action-button" href={require('../../../assets/map.png')} target="_blank" rel="noreferrer">
                    Download Brochure
                </a>
            </article>

            <section className="specials-cards">
                <ServiceCard
                    image={UniversitySelection}
                    name="University Selection"
                    description="Personalized assistance in choosing the right university based on your academic goals, preferences, and career aspirations."
                />
                <ServiceCard
                    image={VisaAssistance}
                    name="Visa Assistance"
                    description="Guidance and support throughout the visa application process, ensuring compliance with all requirements for a smooth transition to your chosen destination."
                />
                <ServiceCard
                    image={CareerGuidance}
                    name="Career Guidance"
                    description="Expert advice and counseling to help you align your academic choices with your long-term career objectives, ensuring a rewarding professional journey after graduation."
                />
            </section>

            <section className="specials-carousel">
                <h2>Discover More Services</h2>
                <p>Explore a variety of consultancy services designed to enhance your academic and career prospects.</p>
                {/* You can use the existing Carousel component or create a new one */}
                {/* <Carousel /> */}
            </section>
        </section>
    );
}
