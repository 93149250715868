import React from 'react';
import '../pages/contact.css';

const ContactPage = () => {
    return (
        <div className="contact-container">
            <div className="contact-info">
                <h1>Contact Us</h1>
                

                <h2>Office Address:</h2>
                <p>3rd Floor,</p>
                <p>7 Kirkdale Rd,</p>
                <p>Bushwood,</p>
                <p>London E11 1HP, UK</p>
            </div>

            <div className="contact-form">
                <h1>Contact Form</h1>
                <form>
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" name="name" required />

                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" required />

                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" rows="4" required></textarea>

                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default ContactPage;
