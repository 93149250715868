import React from 'react';
import '../../App.css';
import Specials from '../sections/headingPages/Specials';
// import Testimonials from '../sections/headingPages/Testimonials';


export default function ServicesPage() {
  return (
    <>
      <Specials />
      {/* <Testimonials /> */}
    </>
  );
}
