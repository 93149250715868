import "./App.css";
import Navbar from "./components/Navbar";
import Routing from "./components/Routing";
import Footer from "./components/Footer";
import Footer2 from "./components/Footer2";
function App() {
  return (
    <>
      <Navbar />
      <Routing />
      {/* <Footer /> */}
      <Footer2 />

    </>
  );
}

export default App;
