import React from 'react';

const ServiceCard = ({ image, name, description }) => {
    return (
        <div className="service-card">
            <img src={image} alt={name} className="service-image" />
            <div className="service-details">
                <h3 className="service-name">{name}</h3>
                <p className="service-description">{description}</p>
            </div>
        </div>
    );
};

export default ServiceCard;
